<template>
  <!-- CLIENTS LOGOS SLIDER-->
  <page-section >
    <ws-slider
        :items="sliderItems"
        class="mt-5"
        :height="300"
        width="500"

        hide-header
        not-centered
    >

      <template #item="{item}">

      <v-sheet
          class="fill-height d-flex flex-column pa-5 wsRoundedSemi"
          color="red"
          :style="{ backgroundImage: `url(${require(`@modules/wsu_homepage/assets/img/universities/university_${item.item.value}.jpg`)}) !important` }"
          style="background-size: cover"

      >
        <div class="flex-grow-1"></div>
        <img :src="require(`@modules/wsu_homepage/assets/img/universities/logo_${item.item.value}.jpg`)"
             height="75"
             width="75"
             style="border-radius: 50%"
             alt=""
             class="mb-4"
        >
        <h3 class="wsWHITE" style="max-width: 80%">{{ $t(`wsu_homepage.universities.${item.item.value}`) }}</h3>
      </v-sheet>
      </template>
    </ws-slider>

  </page-section>


</template>

<script>
export default {
  name: "homeSectionClientLogos",
  components : {
  },
  data() {
    return {

      swiperOption : {
        slidesPerView: 2,
        breakpoints: {
          1024: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          }
        },
        autoplay: {
          delay: 2500,
          stopOnLastSlide: false,
          disableOnInteraction: true,
          loop: true,
        },
        effect: "slide",

        spaceBetween : 16,
        navigation : {
          nextEl : '.clients-swiper-next',
          prevEl : '.clients-swiper-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable : true,
          // renderBullet: (idx, className) =>
          //     `<v-btn small href="#" class="noCaps ${className}">
          //     ${idx}
          //     </v-btn>`
        }
      },
    }
  },
  computed : {
    sliderItems() {
      return  [
        { text : "homepage.sections.testimonials.universities.title",
          value : 'universities',
          items : [
            { value : '1' , img : 1   },
            { value : '2' , img : 2   },
            { value : '3' , img : 3   },
            { value : '4' , img : 4   },
            { value : '5' , img : 5   },
            { value : '6' , img : 6   },
            { value : '7' , img : 7   },
            { value : '8' , img : 8   },
            { value : '9' , img : 9   },
          ]
        }

      ]
    },

    testimonials() {
      return [
        {
          img : 'chukhray',
          business_alias : '',
        },
        {
          img : 'kihteva',
          business_alias : '',
        },
        {
          img : 'tsyos',
          business_alias : '',
        },
        {
          img : 'lutsai',
          business_alias : 'School-Lutsai',
        },
        {
          img : 'kiparenko',
          business_alias : '',
        }
      ]
    }
  }
}
</script>

<style scoped>
h4 {
  font-size: 14px !important;
}
</style>